import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { logger } from '../../helpers';
import storageService from '../storageService';
import { StorageItems } from '../storageService/types';
import ENGAGEMENT_API from './constants';
import {
  CreateUser,
  CreateUserDTO,
  ImportAppDTO,
  ImportApp,
  CreateUserRes,
  EndEngagement,
  EndEngagementDTO,
  EngagementServiceInterface,
  MakeBooleanRequest,
  SendMessage,
  SendMessageDTO,
  StartEngagement,
  StartEngagementDTO,
} from './types';

export class EngagementService implements EngagementServiceInterface {
  private contentType = 'application/json';

  private api: AxiosInstance | null = null;

  constructor(private readonly baseUrl: string) {
    this.baseUrl = baseUrl;

    this.api = this.createAxiosInstance(this.baseUrl);
  }

  private createAxiosInstance = (baseURL: string) => {
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': this.contentType,
        Accept: this.contentType,
      },
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        const { headers } = config;
        const token = storageService.getToken();

        if (headers && token) {
          headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        logger(error);
        return Promise.reject(error);
      },
    );

    return axiosInstance;
  };

  private makeBooleanRequest: MakeBooleanRequest = async (action, dto) => {
    if (!this.api) {
      throw new Error("Engagement API doesn't exist");
    }

    const chatId = storageService.getChatId();

    if (!chatId) {
      throw new Error('Session expired');
    }

    const url = ENGAGEMENT_API.endpoints[action](chatId);
    const { status } = await this.api.post(url, dto);

    return status >= 200 && status < 300;
  };

  public createUser: CreateUser = async ({ appId, preview }) => {
    if (!this.api) {
      throw new Error("Engagement API doesn't exist");
    }

    const createUserDTO: CreateUserDTO = {
      app_id: appId,
      browser_language: window.navigator.language || 'en-US',
      referring_site: 'www.example.com',
      user_browser: window.navigator.userAgent,
      ...(preview && {
        passthrough: JSON.stringify({ preview }),
      }),
    };

    const { data } = await this.api.post<CreateUserDTO, AxiosResponse<CreateUserRes | null>>(
      ENGAGEMENT_API.endpoints.createUser,
      createUserDTO,
    );

    if (!data) {
      throw new Error('No user data received');
    }

    storageService.set(StorageItems.userInfo, data);

    return data;
  };

  public importApp: ImportApp = async ({ appId, streamId, pypeId }) => {
    if (!this.api) {
      throw new Error("Engagement API doesn't exist");
    }

    const importAppDTO: ImportAppDTO = {
      stream_id: streamId,
      pype_id: pypeId,
    };

    await this.api.post<CreateUserDTO, AxiosResponse<void>>(
      ENGAGEMENT_API.endpoints.importApp(appId),
      importAppDTO,
    );

  };

  public startEngagement: StartEngagement = async ({ appId, userId, streamId }) => {
    logger('startEngagement');

    const startEngagementDTO: StartEngagementDTO = {
      app_id: appId,
      consumer: `consumer_${userId}`,
      gateway: 'pypestream_widget',
      stream_id: streamId,
      user_id: userId,
      version: '1',
    };

    const isSuccess = await this.makeBooleanRequest('start', startEngagementDTO);

    return isSuccess;
  };

  public sendMessage: SendMessage = async ({ userId, message }) => {
    logger('sendMessage');

    const sendMessageDTO: SendMessageDTO = {
      from: userId,
      from_side: 'consumer',
      gateway: 'pypestream_widget',
      msg: message,
      msg_type: 'text',
      user_id: userId,
      version: '1',
    };

    const isSuccess = await this.makeBooleanRequest('message', sendMessageDTO);

    return isSuccess;
  };

  public endEngagement: EndEngagement = async ({ userId, pypeId }) => {
    logger('endEngagement');

    const endEngagementDTO: EndEngagementDTO = {
      ended_by: `consumer_${userId}`,
      pype_id: pypeId,
      source: 'client',
      user_id: userId,
      end_comment: 'Goodbye',
      version: '1',
    };

    const isSuccess = await this.makeBooleanRequest('end', endEngagementDTO);

    if (isSuccess) {
      storageService.remove(StorageItems.userInfo);
    }

    return isSuccess;
  };
}

export default EngagementService;
