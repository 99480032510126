import { S3_API_URL_LIVE, S3_API_URL_SANDBOX } from '../../env';

const S3_API = {
  url: {
    live: S3_API_URL_LIVE,
    sandbox: S3_API_URL_SANDBOX,
  },
};

export default S3_API;
