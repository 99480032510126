/* eslint-disable no-use-before-define */
import { RefObject, useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer';
import { notification, Button } from '@pypestream/fishercat-ui';
import { convertMinToMS, formatSeconds } from '../helpers';
import { useMessagingContext } from './useMessagingContext';

export const useIdleTimer = (appId: string) => {
    const [focusTimeout, setFocusTimeout] = useState<NodeJS.Timeout>();
    const [idleTime, setIdleTime] = useState<number>(0);
    const { end } = useMessagingContext();
    const toastId = useRef<string | number | undefined>(undefined);
    // time till session end message will be show
    const sessionTimeoutInMS = convertMinToMS(28);
    // the time the message is shown before the session ends
    const warningTimeoutInMS = convertMinToMS(2);

    const dismissNotification = () => notification.dismiss(toastId.current);

    const reloadButtonRef = useRef<HTMLButtonElement>(null);
    const extendButtonRef = useRef<HTMLButtonElement>(null);

    const focusButton = (ref: RefObject<HTMLButtonElement>) => {
      setFocusTimeout(setTimeout(() => ref.current?.focus(), 500));
    };

    const clearFocusTimeout = () => {
      if (focusTimeout) {
        clearTimeout(focusTimeout);
      }
    };

    const resetAllActions = () => {
        dismissNotification();
        reset();
        pause();
        clearFocusTimeout();
    };

    const extendSession = () => {
        dismissNotification();
        reset();
        clearFocusTimeout();
    };

    const showSessionEndMessage = () => {
      toastId.current = notification(
        'Session expired',
        <Button ref={reloadButtonRef} size="small" onClick={() => window.location.reload()}>
            Reload
        </Button>,
      );
      focusButton(reloadButtonRef);
    };

    const showWarning = () => {
        toastId.current = notification(
          `Session will expire in ${formatSeconds(warningTimeoutInMS / 1000)} due to inactivity`,
          <Button ref={extendButtonRef} size="small" onClick={extendSession}>
              Extend
          </Button>,
        );
        focusButton(extendButtonRef);
    };

    const onAction = () => {
        if (!isPrompted()) {
            reset();
        }
    };

    const onIdle = () => {
        reset();
        pause();
        dismissNotification();
        showSessionEndMessage();
        end?.();
    };

    const {
        start,
        reset,
        pause,
        isPrompted,
        getRemainingTime,
      } = useReactIdleTimer({
        onPrompt: showWarning,
        onIdle,
        onAction,
        timeout: sessionTimeoutInMS,
        promptTimeout: warningTimeoutInMS,
        events: [
          'mousemove',
          'keydown',
          'keypress',
          'wheel',
          'scroll',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'click',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          'visibilitychange'
        ],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        startOnMount: false,
        startManually: false,
        stopOnIdle: true,
        syncTimers: 0
      });

      useEffect(() => {
        if (!appId) {
          return;
        }

        start();

        const intervalId = setInterval(() => {
          const iddleTime = Number((getRemainingTime() / 1000).toFixed(0));

          if (iddleTime <= 0) {
            clearInterval(intervalId);
            setIdleTime(0);

            return;
          }

          setIdleTime(iddleTime);
        }, 1000);

        return () => {
          clearInterval(intervalId);
          resetAllActions();
        };
      }, [appId]);

      useBeforeunload(() => {
        resetAllActions();
      });

    return {
      idleTime,
      isSessionAboutToExpire: idleTime < warningTimeoutInMS / 1000
    };
};

export default useIdleTimer;
