import { FC, memo } from 'react';
import { Button, Image } from '@pypestream/fishercat-ui';
import { ScreenEmbedComponentsConfig } from '../types';
import { useFishercatEmbedComponent } from '../../../hooks';

type ImageScreenProps = ScreenEmbedComponentsConfig;

export const ImageScreen: FC<ImageScreenProps> = memo(({ config }) => {
    const { handleClick } = useFishercatEmbedComponent(config);
    const { mediaSrc, alt_text: altText, cta } = config;

    return (
        <>
            <Image src={mediaSrc || ''} alt={altText || ''} />
            {cta && (
                <Button onClick={() => handleClick({ nextPageId: cta.value, id: cta.id })}>{cta.title || 'Next'}</Button>
            )}
        </>
    )
});

export default ImageScreen;
