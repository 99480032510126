import { ScreenConfig } from "../../../../context/AppContextProvider/types";
import { MarkdownTitle as Title } from "../../MarkdownTitle";
import { getRandomString } from "../../../../helpers";

export const renderScreenTitles = (titles: ScreenConfig['titles'], isError: boolean) => {
    const errorTitle = isError ? { title: 'App id doesn\'t exist', id: getRandomString() } : null;
    const screenTitles = [errorTitle, ...(titles || [])];

    return screenTitles?.map((item) => {
        return item?.title && (
            <Title key={item.id} id={item.id}>
                {item.title}
            </Title>
        );
    })
};

export default renderScreenTitles;
