import { useState, useCallback } from "react";
import { UsersData } from "../context/FishercatContextProvider/types";
import { MutatedEmbedItem } from "../services/fishecatApiService/types";
import { useFishercatContext } from "./useFishercatContext";

interface ClickHandlerProps {
  nextPageId: string;
  id: string;
  data?: Partial<UsersData>;
}

export const useFishercatEmbedComponent = (config: MutatedEmbedItem) => {
    const { step, activePage, setNextPage, addUserData, saveToVariable } = useFishercatContext();
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [active, setActive] = useState<string[]>([]);

    const {
      multiple_choice: isMultyChoise,
      saveTo
    } = config;

    const titleId = activePage?.title ? activePage.id : undefined;

    const handleClick = useCallback(({ nextPageId, id, data }: ClickHandlerProps) => {
        const isItemActive = active.includes(id);

        if (isItemActive) {
          setActive(active.filter((item) => item !== id));
        } else {
          setActive([...active, id]);
        }

        if (saveTo) {
          saveToVariable?.(saveTo, data?.input || '')
        }

        if (!isMultyChoise && !isClicked) {
            setIsClicked(true);

            addUserData?.({
              step,
              id,
              ...data
            });

            setNextPage?.(nextPageId);
        }
    }, [active]);

    return {
        titleId,
        active,
        handleClick,
    }
};

export default useFishercatEmbedComponent;
