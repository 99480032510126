import { FC, memo } from "react";
import { ImageButtons } from "@pypestream/fishercat-ui";
import { useFishercatEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type ButtonsScreenProps = ScreenEmbedComponentsConfig;

export const ButtonsScreen: FC<ButtonsScreenProps> = memo(({ config }) => {
    const { titleId, active, handleClick } = useFishercatEmbedComponent(config);

    return (
        <ImageButtons ariaLabelledby={titleId}>
            {config.options?.map(({ id, value = '', image = '', alt_text: imgAlt = '', title }) => (
                <ImageButtons.Button
                    key={id}
                    imgAlt={imgAlt || title}
                    imgSrc={image}
                    onClick={() => handleClick({ nextPageId: value, id })}
                    active={active.includes(id)}
                />
            ))}
        </ImageButtons>
    );
});

export default ButtonsScreen;
