import { FC, memo } from "react";
import { Button, ListPicker } from "@pypestream/fishercat-ui";
import { ScreenEmbedComponentsConfig } from "../types";
import { useFishercatContext, useFishercatEmbedComponent } from "../../../hooks";

type ListPickerScreenProps = ScreenEmbedComponentsConfig;

export const ListPickerScreen: FC<ListPickerScreenProps> = memo(({ config }) => {
    const { titleId, active, handleClick } = useFishercatEmbedComponent(config);
    const { multiple_choice: isMultyChoise, options, cta } = config;
    const { step, addUserData, setNextPage } = useFishercatContext();

    const onSubmit = () => {
        addUserData?.({
            step,
            id: active.join(', ')
        });

        setNextPage?.(cta?.value || '')
    };

    return (
        <>
            <ListPicker ariaLabelledby={titleId} isMulti={isMultyChoise}>
                {options?.map(({ id, image, value = '', title, alt_text: imgAlt, subtitle }) => (
                    <ListPicker.Item
                        key={id}
                        title={title}
                        imgAlt={imgAlt || title}
                        imgSrc={image}
                        subtitle={subtitle}
                        onClick={() => handleClick({ nextPageId: value, id })}
                        active={active.includes(id)}
                    />
                ))}
            </ListPicker>
            {cta && isMultyChoise && (
                <>
                    <div style={{ marginBottom: 60 }} />
                    <Button
                        disabled={!active.length}
                        onClick={onSubmit}
                    >
                        {cta.title || 'Next'}
                    </Button>
                </>
            )}
        </>
    );
});

export default ListPickerScreen;
