import axios from "axios";
import { FC, memo, useState } from "react";
import { Button, FileDownload } from "@pypestream/fishercat-ui";
import { formatBytes, getProgress, logger } from "../../../helpers";
import { ScreenEmbedComponentsConfig } from "../types";
import { useFishercatEmbedComponent } from "../../../hooks";

type FileDownloadScreenProps = ScreenEmbedComponentsConfig;

export const FileDownloadScreen: FC<FileDownloadScreenProps> = memo(({ config }) => {
    const { titleId, handleClick } = useFishercatEmbedComponent(config);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const size = total !== undefined ? formatBytes(total) : '';
    const { cta } = config;

    const onDownload = async () => {
        await axios
            .request({
                method: "get",
                url: config.mediaSrc || '',
                responseType: 'blob',
                onDownloadProgress: ({ loaded, total }) => {
                    setTotal(total || loaded);
                    const progress = total ? getProgress(loaded, total) : 100;
                    setProgress(progress);
                }
            })
            .then((response) => {
                const { url } = response.config;
                const link = document.createElement('a');
                link.href = url || '';
                link.setAttribute('download', 'file');
                link.setAttribute('target', '_blank');
                link.click();
            })
            .catch((error) => {
                logger(error);
                setIsError(true);
            });
    };

    return (
        <>
            <FileDownload
                ariaLabelledby={titleId}
                url={config.mediaSrc || ''}
                name="document"
                size={size}
                progress={progress}
                error={isError}
                onDownload={onDownload}
            />
            {cta && (
                <Button onClick={() => handleClick({ nextPageId: cta.value, id: cta.id })}>{cta.title || 'Next'}</Button>
            )}
        </>
    );
});

export default FileDownloadScreen;
