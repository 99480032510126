import { createContext, FC, useMemo } from "react";
import { useFishercatApi } from "../../hooks";
import { FishercatContextProps, FishercatContextProviderProps } from "./types";

export const FishecatContext = createContext<FishercatContextProps>({
    step: 0,
    projectConfig: null,
    activePage: null
});

export const FishercatContextProvider: FC<FishercatContextProviderProps> = ({ children }) => {
    const {
        appId,
        streamId,
        pypeId,
        projectConfig,
        activePage,
        step,
        setNextPage,
        addUserData,
        saveToVariable
    } = useFishercatApi();

    // Don't forget add dependencies to useMemo dep array
    const appValue: FishercatContextProps = useMemo(() => ({
        step,
        appId,
        streamId,
        pypeId,
        projectConfig,
        activePage,
        setNextPage,
        addUserData,
        saveToVariable
    }), [step, appId, projectConfig, activePage, streamId, pypeId]);

    return (
        <FishecatContext.Provider value={appValue}>
            {children}
        </FishecatContext.Provider>
    );
};

export default FishercatContextProvider;
