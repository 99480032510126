import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useAppContext, useIsomorphicLayoutEffect, useMessagingContext } from '.';
import { LAUNCHDARKLY_ENV } from '../env';
import { scrollToTop, isEmpty } from '../helpers';
import storageService from '../services/storageService';

export const useInitializeApp = () => {
  const { appId, streamId, pypeId, preview, step, widgetConfig } = useAppContext();
  const { start, end } = useMessagingContext();
  const [isError, setIsError] = useState<boolean>(false);
  const ldClient = useLDClient();
  const [documentReady, setDocumentReady] = useState<boolean>(false);
  const flags = useFlags();

  const { pe21974, pe22624 } = flags;

  useEffect(() => {
    const id = setInterval(() => {
      const isReady = document.readyState === 'complete';

      if (documentReady) {
        clearInterval(id);

        return;
      }

      setDocumentReady(isReady);
    }, 500);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (!documentReady || !pe21974) {
      return;
    }

    const id = setTimeout(() => {
      setIsError(!appId);
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  }, [documentReady, appId, pe21974]);

  useEffect(() => {
    if (!appId) {
      return;
    }

    ldClient?.identify({
      key: appId,
      custom: {
        environment: LAUNCHDARKLY_ENV || '',
      },
    });
  }, [appId]);

  useEffect(() => {
    if (appId && start && !isEmpty(flags)) {
      start({
        appId,
        pypeId,
        streamId,
        preview,
        flags,
      });
    }
  }, [appId, pypeId, streamId, start, flags]);

  useEffect(() => {
    const sheetId = 'psfc-styles';
    let sheet = document.getElementById(sheetId);

    if (!widgetConfig?.custom_pype_css || !pe22624 || sheet) {
      return;
    }

    sheet = document.createElement('style');
    // @ts-ignore
    sheet.type = 'text/css';
    sheet.id = sheetId;
    sheet.innerHTML = widgetConfig.custom_pype_css;
    document.head.appendChild(sheet);
  }, [widgetConfig?.custom_pype_css, pe22624]);

  useIsomorphicLayoutEffect(() => {
    scrollToTop();
  }, [step]);

  useBeforeunload(() => {
    const chatId = storageService.getChatId();

    if (!chatId) {
      return;
    }

    end?.();
  });

  return isError;
};

export default useInitializeApp;
