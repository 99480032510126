import { FC, memo } from "react";
import { Webview } from "@pypestream/fishercat-ui";
import { useEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type WebViewScreenProps = ScreenEmbedComponentsConfig;

export const WebViewScreen: FC<WebViewScreenProps> = memo(({ config }) => {
    const { title } = useEmbedComponent(config);

    return (
        <>
            {title}
            <Webview src={config.url || ''} title={config.label || ''} />
        </>
    );
});

export default WebViewScreen;
