import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { logger } from '../../helpers';
import {
  DecodeFunction,
  StartFishercatExpirience,
  ProjectType,
  Page,
  Titles,
  MutatedPage,
} from './types';

export class FishercatApiService {
  private contentType = 'application/json';

  private api: AxiosInstance | null = null;

  private decode: DecodeFunction = (str) => JSON.parse(decodeURIComponent(escape(atob(str))));

  private mutateTitle = ({ editorChanged, editor }: Titles) => (editorChanged ? editor : '');

  private mutateManifest = (manifest: string): MutatedPage[] => {
    return this.decode<Page[]>(manifest).map((page) => {
      const { title, embeds } = page;
      const mutatedTitle = this.mutateTitle(title);

      const updatedEmbeds = embeds.map((embed) => {
        const { title, options, cta } = embed;
        const mutatedEmbedTitle = this.mutateTitle(title);
        const ctaTitle = cta?.title ? this.mutateTitle(cta?.title) : '';

        const updatedOptions = options.map((option) => {
          const { title } = option;
          const mutatedOptionTitle = this.mutateTitle(title);

          return {
            ...option,
            title: mutatedOptionTitle,
          };
        });

        return {
          ...embed,
          title: mutatedEmbedTitle,
          options: updatedOptions,
          cta: cta
            ? {
                ...cta,
                title: ctaTitle,
              }
            : null,
        };
      });

      return {
        ...page,
        title: mutatedTitle,
        embeds: updatedEmbeds,
      };
    });
  };

  constructor(private readonly baseUrl: string) {
    this.baseUrl = baseUrl;

    this.api = this.createAxiosInstance(this.baseUrl);
  }

  private createAxiosInstance = (baseURL: string) => {
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': this.contentType,
        Accept: this.contentType,
      },
    });

    return axiosInstance;
  };

  public start: StartFishercatExpirience = async (appId: string) => {
    if (!this.api) {
      throw new Error("Fishercat API doesn't exist");
    }

    const {
      data: { project },
    } = await this.api.get<null, AxiosResponse<{ project: ProjectType }>>(`start/${appId}`);

    let manifest: MutatedPage[] = [];

    if (project.manifest) {
      manifest = this.mutateManifest(project.manifest);
    }

    const decodedProject = {
      ...project,
      manifest,
    };

    logger(decodedProject);

    return decodedProject;
  };
}

export default FishercatApiService;
