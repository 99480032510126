import { FC, memo, useState } from "react";
import { Button, DatePicker } from "@pypestream/fishercat-ui";
import { useFishercatEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";
import { getRanges } from "./getRanges";
import { DatepickerOptions } from "../../../services/fishecatApiService/types";
// import { months } from "./months";

type DatePickerScreenProps = ScreenEmbedComponentsConfig;

export const DatePickerScreen: FC<DatePickerScreenProps> = memo(({ config }) => {
    const { titleId, handleClick } = useFishercatEmbedComponent(config);
    const [date, setDate] = useState<Date>(new Date());;
    const onChange = (date: Date) => setDate(date);
    const {
        cta,
        dates
    } = config;
    const { maxYear, minYear } = getRanges(dates?.dateRange);

    // TODO: create datepicker submit functionality
    const onSubmit = () => {
        const dateDay = String(date.getDate());
        const dateMonth = date.getMonth();
        // const monthName = months[dateMonth];
        const monthNumberInYear = String(dateMonth + 1);
        const day = dateDay.length < 2 ? `0${dateDay}` : dateDay;
        const month = monthNumberInYear.length < 2 ? `0${monthNumberInYear}` : monthNumberInYear;
        const year = date.getFullYear();
        // const msg = `${monthName} ${date.getDate()}, ${year}`;

        const getDateMessage = (options?: DatepickerOptions) => {
            const y = options?.year ? year : null;
            const m = options?.month ? month : null;
            const d = options?.day ? day : null;

            return [y, m, d]
                .filter((item) => item)
                .join('-')
        };

        handleClick({
            nextPageId: cta?.value || '',
            id: cta?.id || '',
            data: {
                input: getDateMessage(dates)
            }
        });
    };

    return (
        <>
            <DatePicker
                maxYear={maxYear}
                minYear={minYear}
                value={date}
                ariaLabelledby={titleId}
                day={dates?.day}
                month={dates?.month}
                year={dates?.year}
                onChange={onChange}
            />
            {cta && (
                <Button onClick={onSubmit}>{cta.title || 'Next'}</Button>
            )}
        </>
    );
});

export default DatePickerScreen;
