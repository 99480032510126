import axios from "axios";
import { FC, memo, useState } from "react";
import { FileDownload } from "@pypestream/fishercat-ui";
import { formatBytes, getProgress, logger } from "../../../helpers";
import { MediaFile } from "../types";

interface FileDownloadScreenProps {
    file: MediaFile;
}

export const FileDownloadScreen: FC<FileDownloadScreenProps> = memo(({ file }) => {
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const size = total !== undefined ? formatBytes(total) : '';

    const onDownload = async () => {
        await axios
            .request({
                method: "get",
                url: file.src || '',
                responseType: 'blob',
                onDownloadProgress: ({ loaded, total }) => {
                    setTotal(total || loaded);
                    const progress = total ? getProgress(loaded, total) : 100;
                    setProgress(progress);
                }
            })
            .then((response) => {
                const { url } = response.config;
                // console.log('response', response)
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url || '';
                // link.setAttribute('download', 'file.pdf');
                link.setAttribute('target', '_blank');
                link.click();
            })
            .catch((error) => {
                logger(error);
                setIsError(true);
            });
    };

    return (
        <FileDownload
            ariaLabelledby={file.id}
            url={file.src || ''}
            name="document"
            size={size}
            progress={progress}
            error={isError}
            onDownload={onDownload}
        />
    );
});

export default FileDownloadScreen;
