import { FC, memo, useState } from "react";
import { Button, DatePicker } from "@pypestream/fishercat-ui";
import { useEmbedComponent } from "../../../hooks";
import { SendEmbedMessagePayload } from "../../../services/webSocketService/types";
import { ScreenEmbedComponentsConfig } from "../types";
import { months } from "./months";

type DatePickerScreenProps = ScreenEmbedComponentsConfig;

export const DatePickerScreen: FC<DatePickerScreenProps> = memo(({ config }) => {
    const { title, titleId, handleSendEmbed } = useEmbedComponent(config);
    const [date, setDate] = useState<Date>(new Date());;
    const onChange = (date: Date) => setDate(date);

    const onSubmit = () => {
        const dateDay = String(date.getDate());
        const dateMonth = date.getMonth();
        const monthName = months[dateMonth];
        const monthNumberInYear = String(dateMonth + 1);
        const day = dateDay.length < 2 ? `0${dateDay}` : dateDay;
        const month = monthNumberInYear.length < 2 ? `0${monthNumberInYear}` : monthNumberInYear;
        const year = date.getFullYear();
        const msg = `${monthName} ${date.getDate()}, ${year}`;

        const payload: Partial<SendEmbedMessagePayload> = {
            msg,
            app_object: JSON.stringify({
                bot_value: `${year}-${month}-${day}`,
                free_text: true,
                id: '',
                msg,
                type: 'text'
            })
        };

        handleSendEmbed(payload);
    };

    return (
        <>
            {title}
            <DatePicker value={date} ariaLabelledby={titleId} onChange={onChange} />
            <Button onClick={onSubmit}>Next</Button>
        </>
    );
});

export default DatePickerScreen;
