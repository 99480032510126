import { useState, useEffect } from 'react';
import { UsersData } from '../context/FishercatContextProvider/types';
import { FishercatApiService } from '../services/fishecatApiService';
import FISHERCAT_API from '../services/fishecatApiService/constants';
import { DecodedProject, MutatedPage } from '../services/fishecatApiService/types';
import useGetQueryParams from './useGetQueryParams';

export const useFishercatApi = () => {
  const { appId, pypeId, streamId } = useGetQueryParams();
  const [projectConfig, setProjectConfig] = useState<DecodedProject | null>(null);
  const [activePage, setActivePage] = useState<MutatedPage | null>(null);
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const [userData, setUserData] = useState<UsersData[]>([]);

  // console.log('userData', userData);
  // console.log('projectConfig', projectConfig);

  const catchError = (error: Error) => setError(error);

  const setNextStep = () => setStep((step) => step + 1);

  const setNextPage = (pageId: string) => {
    const nextPage = projectConfig?.manifest.find(({ id }) => id === pageId) || null;

    setActivePage(nextPage);
    setNextStep();
  };

  const addUserData = (data: UsersData) => setUserData((savedData) => [...savedData, data]);

  const saveToVariable = (varName: string, value: string) => {
    const updatedVariables =
      projectConfig?.variables.map((variable) => {
        const { label } = variable;

        if (label === varName) {
          return {
            label,
            value,
          };
        }

        return variable;
      }) || [];

    setProjectConfig(
      projectConfig
        ? {
            ...projectConfig,
            variables: updatedVariables,
          }
        : null,
    );
  };

  useEffect(() => {
    if (!appId) {
      return;
    }

    const fishercatService = new FishercatApiService(FISHERCAT_API.url);

    fishercatService
      .start(appId)
      .then((project) => {
        if (!project) {
          return;
        }

        setProjectConfig(project);
        setActivePage(project.manifest[0]);
      })
      .catch((e) => catchError(e));
  }, [appId]);

  useEffect(() => {
    if (error) {
      throw new Error(error.message, error);
    }
  }, [error]);

  return {
    appId,
    streamId,
    pypeId,
    projectConfig,
    activePage,
    step,
    userData,
    setNextPage,
    addUserData,
    saveToVariable,
  };
};
