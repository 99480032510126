import { useState, useEffect } from 'react';
import { useAppContext } from '.';
import { CreateProps } from '../context/MessagingContextProvider/types';
import { IS_DEV_MODE } from '../env';
import { EngagementService } from '../services/engagementService';
import ENGAGEMENT_API from '../services/engagementService/constants';
import { MessagingService } from '../services/messagingService';
import { S3Service } from '../services/s3Servise';
import S3_API from '../services/s3Servise/constants';
import { WebSocketService } from '../services/webSocketService';
import { SendEmbedMessagePayload } from '../services/webSocketService/types';

interface EngagementsFunctions {
  create?: (props: CreateProps) => void;
  send?: (message: string) => void;
  sendEmbed?: (data: Partial<SendEmbedMessagePayload>) => void;
  end?: () => Promise<void>;
}

export const useEngagement = () => {
  const { env, setWidgetConfig, setScreenConfig, setNextStep, catchError } = useAppContext();
  const [functions, setFunctions] = useState<EngagementsFunctions>({
    create: undefined,
    send: undefined,
    sendEmbed: undefined,
    end: undefined,
  });

  useEffect(() => {
    if (!env) {
      return;
    }

    const defaultEngagementUrl = IS_DEV_MODE ? ENGAGEMENT_API.url.sandbox : ENGAGEMENT_API.url.live;

    const engagementUrl = ENGAGEMENT_API.url[env] || defaultEngagementUrl;

    const defaultS3Url = IS_DEV_MODE ? S3_API.url.sandbox : S3_API.url.live;

    const s3Url = S3_API.url[env] || defaultS3Url;

    const defaultWSUrl = IS_DEV_MODE
      ? ENGAGEMENT_API.socketUrl.sandbox
      : ENGAGEMENT_API.socketUrl.live;

    const WSUrl = ENGAGEMENT_API.socketUrl[env] || defaultWSUrl;

    const messagingService = new MessagingService({
      webSocket: new WebSocketService(WSUrl),
      engagement: new EngagementService(engagementUrl),
      s3: new S3Service(s3Url),
    });

    const create = ({ appId, streamId, pypeId, preview, flags }: CreateProps) => {
      messagingService.create({
        appId,
        pypeId,
        streamId,
        preview,
        flags,
        setScreenConfig,
        setWidgetConfig,
        catchError,
      });
    };

    const send = (message: string) => {
      setNextStep?.();
      messagingService.send(message);
    };

    const sendEmbed = (data: Partial<SendEmbedMessagePayload>) => {
      setNextStep?.();
      messagingService.sendEmbed(data);
    };

    const end = () => messagingService.end();

    setFunctions({
      create,
      send,
      sendEmbed,
      end,
    });
  }, [env]);

  return functions;
};

export default useEngagement;
