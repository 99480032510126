import { FC, ChangeEvent, useState, KeyboardEvent, useCallback, useMemo, memo } from "react";
import { Icon, IconButton, TextInput } from "@pypestream/fishercat-ui";
import { useEmbedComponent, useMessagingContext } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type FormScreenProps = ScreenEmbedComponentsConfig;

export const FormScreen: FC<FormScreenProps> = memo(({ config }) => {
    const { title, titleId } = useEmbedComponent(config);
    const { send } = useMessagingContext();
    const [value, setValue] = useState<string>('');

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target?.value);
    }, []);

    const onButtonClick = useCallback(() => {
        send?.(value);
    }, [value]);

    const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && value) {
            onButtonClick();
        }
    }, [value]);

    const suffix = useMemo(() => (
        <IconButton label="Continue" disabled={!value} onClick={onButtonClick}>
            <Icon name="arrow-left" />
        </IconButton>
    ), [value]);

    return (
        <>
            {title}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput
                    // label="Start typing…"
                    aria-labelledby={titleId}
                    suffix={suffix}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                />
            </div>
        </>
    );
});

export default FormScreen;
