import { FC, memo } from 'react';
import { Layout, Image, Video, Stepper, Header, NotificationContainer } from '@pypestream/fishercat-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EndButton } from '..';
import { DecodedEmbedObject, ScreenConfig } from '../../../context/AppContextProvider/types';
import { DivElement } from '../../../types/globals';
import { FileDownloadScreen, Menu } from '../../screens';
import { renderEmbedComponents, renderScreenTitles } from './helpers';

interface ScreenLayoutProps extends DivElement {
    config: ScreenConfig;
    loading?: boolean;
    step: string;
    logo?: string;
    error: boolean;
    menu?: DecodedEmbedObject;
}

export const ScreenLayout: FC<ScreenLayoutProps> = memo(({
    logo,
    config,
    menu,
    loading,
    step,
    error
}) => {
    const { background, photo, video, embed, document, titles } = config;
    const { pe22016 } = useFlags();

    return (
        <>
            <Header>
                {logo && (
                    <Header.Logo src={logo} alt="logo" />
                )}
                {menu && (
                    <Menu config={menu} />
                )}
                <EndButton />
            </Header>
            <NotificationContainer />
            <main>
                <Stepper activeId={step} showLabel={pe22016}>
                    <Layout
                        coverSrc={background?.src}
                        coverType={background?.type}
                        coverAlt={background?.alt}
                        loading={loading}
                    >
                        {renderScreenTitles(titles, error)}
                        {photo && (
                            <Image src={photo.src || ''} alt={photo.alt || ''} />
                        )}
                        {document && (
                            <FileDownloadScreen file={document} />
                        )}
                        {video && (
                            <Video>
                                <source src={`${video.src || ''}#t=0.001`} type="video/mp4" />
                            </Video>
                        )}
                        {renderEmbedComponents(embed)}
                    </Layout>
                </Stepper>
            </main>
        </>
    );
});

export default ScreenLayout;
