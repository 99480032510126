import Head from 'next/head';
import { FC } from "react";
import { IS_DEV_MODE } from '../../../env';
import { useBrowserActiveTab, useIdleTimer } from "../../../hooks";
import { HiddenTimer } from "../HiddenTimer";

interface UserTimeoutProps {
    appId: string;
    title?: string;
}

export const UserTimeout: FC<UserTimeoutProps> = ({ appId, title }) => {
    const { idleTime, isSessionAboutToExpire } = useIdleTimer(appId);
    const isActiveTab = useBrowserActiveTab();

    return (
      <>
        <Head>
          <title>{isSessionAboutToExpire && !isActiveTab ? 'Session about to expire' : title}</title>
        </Head>
        {IS_DEV_MODE && (
          <HiddenTimer time={idleTime} />
        )}
      </>
    );
};

export default UserTimeout;
