import { FC, memo } from "react";
import { Button, Webview } from "@pypestream/fishercat-ui";
import { useFishercatEmbedComponent } from '../../../hooks';
import { ScreenEmbedComponentsConfig } from "../types";

type WebViewScreenProps = ScreenEmbedComponentsConfig;

export const WebViewScreen: FC<WebViewScreenProps> = memo(({ config }) => {
    const { handleClick } = useFishercatEmbedComponent(config);
    const { mediaSrc, title, cta } = config;

    return (
        <>
            <Webview src={mediaSrc || ''} title={title || ''} />
            {cta && (
                <Button onClick={() => handleClick({ nextPageId: cta.value, id: cta.id })}>{cta.title || 'Next'}</Button>
            )}
        </>
    );
});

export default WebViewScreen;
