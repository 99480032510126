import { DatepickerOptions } from '../../../services/fishecatApiService/types';

export const getRanges = (range?: DatepickerOptions['dateRange']) => {
  const currentYear = new Date().getFullYear();
  const century = 100;
  const minYear = currentYear - century;
  const maxYear = currentYear + century;

  switch (range) {
    case 'all-dates':
      return {
        maxYear,
        minYear,
      };
    case 'all-future-years':
      return {
        maxYear,
        minYear: currentYear,
      };
    case 'all-previous-years':
    default:
      return {
        maxYear: undefined,
        minYear: undefined,
      };
  }
};
