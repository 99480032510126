import { FC, memo } from "react";
import { QuickReplies } from "@pypestream/fishercat-ui";
import { ScreenEmbedComponentsConfig } from "../types";
import { useFishercatEmbedComponent } from "../../../hooks";

type QuickReplyScreenProps = ScreenEmbedComponentsConfig;

export const QuickReplyScreen: FC<QuickReplyScreenProps> = memo(({ config }) => {
    const { titleId, active, handleClick } = useFishercatEmbedComponent(config);

    return (
        <QuickReplies ariaLabelledby={titleId}>
            {config.options?.map(({ id, value = '', title  }) => (
                <QuickReplies.Button
                    key={id}
                    onClick={() => handleClick({ nextPageId: value, id })}
                    active={active.includes(id)}
                >
                    {title}
                </QuickReplies.Button>
            ))}
        </QuickReplies>
    );
});

export default QuickReplyScreen;
