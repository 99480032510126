import { Channel, Socket } from 'phoenix';
import logger from '../../helpers/logger';
import storageService from '../storageService';
import {
  WebSocketServiceInterface,
  CreateConnection,
  JoinCannel,
  LeaveChannel,
  EndChatDTO,
  SocketIncomingMessageRes,
  sendEmbedMessage,
} from './types';

export class WebSocketService implements WebSocketServiceInterface {
  private channel: Channel | null = null;

  private sequentialIdentifer = 1;

  private intervalId: NodeJS.Timer | null = null;

  private catchError: ((error: Error) => void) | undefined;

  private reportError = (message: string, reasons?: any) => {
    this.catchError?.(new Error(message, reasons));
  };

  constructor(private readonly baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public createConnection: CreateConnection = ({ token, chatId, userId, catchError }) => {
    this.catchError = catchError;

    const socket = new Socket(this.baseUrl, { params: { token } });
    socket.connect();
    const channel = socket.channel(`chat:${chatId}`, { user_id: userId });
    this.channel = channel;

    socket.onError((reasons) => {
      this.reportError('socket error', reasons);
    });

    return channel;
  };

  private pingChannel = () => {
    const token = storageService.getToken();
    const userId = storageService.getUserId();

    this.intervalId = setInterval(() => {
      if (token && userId) {
        this.channel
          ?.push('new:ping', {
            access_token: token,
            seq: this.sequentialIdentifer,
            user_id: userId,
          })
          .receive('ok', () => {
            this.sequentialIdentifer += 1;
          })
          .receive('error', (reasons) => {
            this.reportError('new:ping error', reasons);
          })
          .receive('timeout', (reasons) => {
            this.reportError('new:ping timeout', reasons);
          });
      }
    }, 20000);
  };

  public joinChannel: JoinCannel = ({
    start,
    setIsReady,
    setChatResponses,
    userId,
    pypeId,
    token,
  }) => {
    if (this.channel?.state === 'joined') {
      return;
    }

    this.channel
      ?.join()
      .receive('ok', () => {
        start();
      })
      .receive('error', (reasons) => {
        this.reportError('channel join error', reasons);
      })
      .receive('timeout', (reasons) => {
        this.reportError('channel join timeout', reasons);
      });

    this.channel?.on('chat:ready', () => {
      this.pingChannel();
      setIsReady?.();
    });

    this.channel?.on('chat:ended', () => {
      this.leaveChannel({ userId, pypeId, token });

      setChatResponses({
        msg_type: 'chat_ended',
      });
    });

    this.channel?.on('incoming:msg', (response: SocketIncomingMessageRes) => {
      logger(response);

      if (
        response.msg === 'Goodbye.' ||
        (response.msg_type === 'system' && response.msg === 'This conversation has ended.')
      ) {
        return;
      }

      setChatResponses(response);
    });

    this.channel?.onError((reasons) => {
      this.reportError('channel error', reasons);
    });
  };

  public sendEmbedMessage: sendEmbedMessage = (payload) => {
    this.channel
      ?.push('msg:send', payload)
      .receive('ok', () => {
        logger('File sent');
      })
      .receive('error', (reasons) => {
        this.reportError('msg:send error', reasons);
      })
      .receive('timeout', (reasons) => {
        this.reportError('msg:send timeout', reasons);
      });
  };

  public leaveChannel: LeaveChannel = ({ userId, pypeId, token }) => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.channel?.state === 'joined') {
      const leaveChannelDto: EndChatDTO = {
        end_comment: 'Goodbye',
        ended_by: `consumer_${userId}`,
        pype_id: pypeId,
        source: 'consumer',
        user_id: userId,
        version: 1,
        access_token: token,
      };

      this.channel
        ?.push('chat:end', leaveChannelDto)
        .receive('ok', () => {
          logger('Chat ended');
        })
        .receive('error', (reasons) => {
          this.reportError('chat:end error', reasons);
        });
      this.channel.leave();
    }
  };
}

export default WebSocketService;
