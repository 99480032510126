import { useFlags } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useInitializeFishercatApp } from '../../../hooks';
import { PypestreamIntegration, UserTimeout } from '..';
import { useFishercatContext } from '../../../hooks/useFishercatContext';
import { FishercatScreenLayout } from '../FishercatScreenLayout';

export const FishercatHomePage: NextPage = () => {
  const { pe21053, pe21735V1 } = useFlags();
  const { appId, projectConfig, activePage, step } = useFishercatContext();
  const isStartError = useInitializeFishercatApp();
  const isLoading = !activePage && !isStartError;
  const logo = projectConfig?.image || '';
  const pageTitle = projectConfig?.name || '';

  return (
    <>
      <Head>
        <meta name="description" content="Fishercat App" />
        <link rel="shortcut icon" href={logo} />
      </Head>
      <FishercatScreenLayout
        config={activePage}
        loading={isLoading}
        logo={logo}
        // menu={persistentMenu}
        step={`${step}`}
        error={isStartError}
      />
      {pe21735V1 && (
        <PypestreamIntegration />
      )}
      {pe21053 && appId && (
        <UserTimeout appId={appId} title={pageTitle} />
      )}
    </>
  );
};

export default FishercatHomePage;
