import { MediaFile } from '../../components/screens/types';
import {
  DecodedEmbedObject,
  EmbedComponentType,
  GeneralComponentType,
  ScreenConfig,
} from '../../context/AppContextProvider/types';
import { getRandomString } from '../../helpers';
import logger from '../../helpers/logger';
import { CreateUserRes } from '../engagementService/types';
import { WidgetConfig } from '../s3Servise/types';
import { SendEmbedMessagePayload, SocketIncomingMessageRes } from '../webSocketService/types';
import {
  CreateChatUser,
  TransormedChatResponse,
  MessagingServiceInterface,
  MessagingServiceProps,
  ScreenOptions,
  SendEmbed,
  FeatureFlags,
} from './types';

export class MessagingService implements MessagingServiceInterface {
  private isStarted = false;

  private userInfo: CreateUserRes | null = null;

  private appId: string | null = null;


  private pypeId?: string | null = null;

  private streamId?: string | null = null;

  private config: WidgetConfig | null = null;

  private chatResponses: SocketIncomingMessageRes[] = [];

  private featureFlags: FeatureFlags | null = null;

  private catchError: ((error: Error) => void) | undefined;

  private setScreenConfig: ((config: ScreenConfig) => void) | undefined;

  constructor(private readonly services: MessagingServiceProps) {
    this.services = services;
  }

  private engagementService = this.services.engagement;

  private webSocketService = this.services.webSocket;

  private s3Service = this.services.s3;

  private start = async () => {
    try {
      const streamId = this.userInfo?.web_chat_stream_id;
      const userId = this.userInfo?.id;

      if (!streamId || !this.appId || !userId) {
        throw new Error('Invalid data to starting engagement');
      }

      this.resetChatResponses();
      await this.engagementService.startEngagement({ appId: this.appId, streamId, userId });
    } catch (error) {
      this.catchError?.(error as Error);
    }
  };

  public create: CreateChatUser = async ({
    appId,
    streamId,
    pypeId,
    preview,
    flags = null,
    setScreenConfig,
    setWidgetConfig,
    catchError,
  }) => {
    try {
      this.catchError = catchError;

      if (this.isStarted) {
        return;
      }

      this.setScreenConfig = setScreenConfig;
      this.isStarted = true;
      this.appId = appId;
      this.pypeId = pypeId;
      this.streamId = streamId;
      this.featureFlags = flags;

      this.config = await this.s3Service.getWidgetConfig(this.appId);

      setWidgetConfig?.(this.config);

      if (this.featureFlags?.pe21814 && pypeId && streamId) {
        await this.engagementService.importApp({
          appId,
          pypeId,
          streamId,
        }).catch(err => logger(err));
      }

      this.userInfo = await this.engagementService.createUser({
        appId,
        preview,
      });


      this.webSocketService.createConnection({
        token: this.userInfo?.access_token || '',
        chatId: this.userInfo?.chat_id || '',
        userId: this.userInfo?.id || '',
        catchError,
      });

      this.webSocketService.joinChannel({
        flags: this.featureFlags,
        userId: this.userInfo?.id || '',
        pypeId: this.userInfo?.web_chat_pype_id || '',
        token: this.userInfo?.access_token || '',
        start: this.start,
        setChatResponses: this.setChatResponses,
      });
    } catch (error) {
      this.catchError?.(error as Error);
    }
  };

  public send = async (message: string) => {
    try {
      const userId = this.userInfo?.id;

      if (!userId) {
        throw new Error('userId is incorrect');
      }

      this.resetChatResponses();
      await this.engagementService.sendMessage({ message, userId });
    } catch (error) {
      this.catchError?.(error as Error);
    }
  };

  public sendEmbed: SendEmbed = async ({ msg, app_object: appObject, file_status: fileStatus }) => {
    try {
      const payload: SendEmbedMessagePayload = {
        access_token: this.userInfo?.access_token || '',
        from: this.userInfo?.id || '',
        from_side: 'anonymous_consumer',
        gateway: 'pypestream_widget',
        msg,
        msg_type: 'embed',
        user_id: this.userInfo?.id || '',
        version: '1',
        ...(appObject && { app_object: this.encode(appObject) }),
        ...(fileStatus && { file_status: fileStatus }),
      };

      this.webSocketService.sendEmbedMessage(payload);
      this.resetChatResponses();
    } catch (error) {
      this.catchError?.(error as Error);
    }
  };

  public end = async () => {
    try {
      const userId = this.userInfo?.id;
      const pypeId = this.userInfo?.web_chat_pype_id;
      const token = this.userInfo?.access_token;

      if (!userId || !pypeId || !token) {
        throw new Error('End engagement credentials are invalid');
      }

      await this.engagementService.endEngagement({ userId, pypeId });
      this.webSocketService.leaveChannel({ userId, pypeId, token });
    } catch (error) {
      this.catchError?.(error as Error);
    }
  };

  private setChatResponses = (chatResponse: SocketIncomingMessageRes) => {
    this.chatResponses.push(chatResponse);
    const components = this.transformWebSocketResponses(this.chatResponses);
    const screenConfig = this.getScreenConfig(components);

    // console.log('components', components);
    // console.log('screenConfig', screenConfig);

    this.setScreenConfig?.(screenConfig);
  };

  private resetChatResponses = () => {
    this.chatResponses = [];
    this.setScreenConfig?.({});
  };

  private transformWebSocketResponses = (chatResponses: SocketIncomingMessageRes[]) => {
    return chatResponses.map((response) => {
      const isEmbedType = response?.msg_type === 'embed';
      const isPhotoType = response?.msg_type === 'photo';
      const isVideoType = response?.msg_type === 'video';
      const isDocumentType = response?.msg_type === 'document';

      if (isEmbedType || isPhotoType || isVideoType || isDocumentType) {
        const decodedObject = this.decode(response?.app_object || '');
        logger(decodedObject);

        return {
          type: response?.msg_type as EmbedComponentType,
          embed: isPhotoType || isVideoType || isDocumentType ? null : decodedObject,
          message: response.msg || '',
          altText: decodedObject?.alt_text || '',
          file: response?.file || null,
        };
      }

      return {
        file: response?.file || null,
        type: response?.msg_type as GeneralComponentType,
        message: response?.msg || '',
        embed: null,
        altText: '',
      };
    });
  };

  private getScreenConfig = (components: TransormedChatResponse[]): ScreenConfig => {
    let options: ScreenOptions = {};
    const updatedEmbed: DecodedEmbedObject[] = options.embed || [];
    const componentsWithoutMenu = components.filter(
      ({ embed }) => embed?.type !== 'persistent_menu',
    );

    // TODO: hucky solution :(
    const componentsWithoutMenuAndMedia = componentsWithoutMenu.filter(
      ({ type }) => type !== 'photo' && type !== 'video',
    );

    // TODO: hucky solution :(
    // eslint-disable-next-line dot-notation
    const clearedComponentsCollection = this.featureFlags?.['pe21880']
      ? componentsWithoutMenuAndMedia
      : componentsWithoutMenu;

    const isForm = clearedComponentsCollection.length
      ? clearedComponentsCollection.every(({ type }) => type === 'text')
      : false;

    const isEndConversation = components.some(({ type }) => type === 'chat_ended');

    // TODO: hucky solution :(
    let formLabel = '';
    // END TODO

    if (isForm && !isEndConversation) {
      // TODO: hucky solution :(
      formLabel = componentsWithoutMenu[0].message;
      // END TODO

      updatedEmbed.push({ type: 'form', label: formLabel });
    }

    components.forEach((component) => {
      const { type, message, file, embed, altText } = component;

      const mediaFileOption = {
        src: file || '',
        alt: altText,
        caption: message,
        id: message ? getRandomString() : undefined,
      };

      if (embed) {
        updatedEmbed.push(embed);
      }

      // TODO: hucky solution :(
      const isFormLabel = isForm && message === formLabel;
      // END TODO

      const newTitleItem =
        message && !isFormLabel ? [{ title: message, id: getRandomString() }] : [];

      switch (type) {
        case 'video':
        case 'photo':
          options = {
            ...options,
            bgType: options.bgType || type,
            [type]: [...(options[type] || []), mediaFileOption],
          };
          break;
        case 'document':
          options = {
            ...options,
            [type]: mediaFileOption,
          };
          break;
        default:
          options = {
            ...options,
            titles: [...(options.titles || []), ...newTitleItem],
          };
      }
    });

    options = {
      ...options,
      embed: updatedEmbed,
    };

    const getMediaFileTitle = ({ caption, id }: MediaFile) => ({
      title: caption,
      id: caption ? id : undefined,
    });

    const { photo, video, document, bgType, titles } = options;
    const screenOptionsPhotos = photo || [];
    const screenOptionsVideos = video || [];
    const isMultipleVideo = !(video?.length === 1 && !photo) && bgType === 'video';
    const pageBackgroundImage = bgType === 'photo' ? screenOptionsPhotos[0] : undefined;
    const pageBackgroundVideo = isMultipleVideo ? screenOptionsVideos[0] : undefined;
    const pageImage = bgType === 'photo' ? screenOptionsPhotos[1] : screenOptionsPhotos[0];
    const pageVideo = isMultipleVideo ? screenOptionsVideos[1] : screenOptionsVideos[0];
    const imageTitles = photo?.map(getMediaFileTitle) || [];
    const videoTitles = video?.map(getMediaFileTitle) || [];
    const documentTitle = (document && [getMediaFileTitle(document)]) || [];
    const screenTitles = [...(titles || []), ...imageTitles, ...videoTitles, ...documentTitle];

    return {
      background: {
        src: pageBackgroundImage?.src || pageBackgroundVideo?.src,
        type: bgType,
        alt: pageBackgroundImage?.alt,
      },
      video: pageVideo,
      photo: pageImage,
      document,
      embed: updatedEmbed,
      titles: screenTitles,
    };
  };

  private decode = (str: string): DecodedEmbedObject =>
    JSON.parse(decodeURIComponent(escape(atob(str))));

  private encode = (str: string) => btoa(unescape(encodeURIComponent(str)));
}

export default MessagingService;
