import { useRouter } from 'next/router';
import { FC } from 'react';
import { IconButton, Icon } from '@pypestream/fishercat-ui';
import { useAppContext } from '../../../hooks';

export const EndButton: FC = () => {
    const { backIfClose } = useAppContext();
    const router = useRouter();

    const onClick = () => {
      if (backIfClose) {
        window.history.back();

        return;
      }

      router.reload()
    };

    return (
      <IconButton size="xlarge" label="Close" onClick={onClick}>
        <Icon name="close" />
      </IconButton>
    );
};

export default EndButton;
