import { FC } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

export const withMarkdown = <T extends Record<string, unknown>>(Component: FC<T>) => {
    return (props: T) => {
        const { children } = props;
        const updatedProps = {
            ...props,
            children: typeof children === 'string' ? <ReactMarkdown remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown> : children
        };

        return (
            <Component {...updatedProps} />
        );
    };
};

export default withMarkdown;
