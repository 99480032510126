import { CreateUserRes } from '../engagementService/types';
import {
  GetItemFromStorage,
  RemoveItemFromStorage,
  SetItemToStorage,
  StorageHelperInterface,
  StorageItems,
} from './types';

abstract class StorageHelper implements StorageHelperInterface {
  public get: GetItemFromStorage = (key) => JSON.parse(String(localStorage.getItem(key)));

  public set: SetItemToStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

  public remove: RemoveItemFromStorage = (key) => localStorage.removeItem(key);
}

class StorageService extends StorageHelper {
  public getUserInfo = () => this.get<CreateUserRes>(StorageItems.userInfo);

  public getToken = () => this.getUserInfo()?.access_token;

  public getChatId = () => this.getUserInfo()?.chat_id;

  public getUserId = () => this.getUserInfo()?.id;

  public endEngagement = () => this.remove(StorageItems.userInfo);
}

export default new StorageService();
