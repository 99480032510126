import axios from 'axios';
import React, { FC, memo, useState } from 'react';
import {
    Button,
    FileUpload,
    FileUploadProps,
    FileWithPath
} from '@pypestream/fishercat-ui';
import { getProgress, logger } from '../../../helpers';
import { useFishercatEmbedComponent } from '../../../hooks';
import { ScreenEmbedComponentsConfig } from '../types';
import FISHERCAT_API from '../../../services/fishecatApiService/constants';

type FileUploadScreenProps = ScreenEmbedComponentsConfig;

export const FileUploadScreen: FC<FileUploadScreenProps> = memo(({ config }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingProgress, setLoadingProgress] = useState<number>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>('');
    const [file, setFile] = useState<FileWithPath | null>(null);
    const { handleClick } = useFishercatEmbedComponent(config);
    const {
        multiple_choice: isMultyChoise,
        cta
    } = config;

    const onDrop: FileUploadProps['onDrop'] = (files) => setFile(files[0]);

    const onDropAccepted: FileUploadProps['onDropAccepted'] = async (files) => {
        const acceptedFile = files[0];
        const acceptedFileName = acceptedFile.name;

        setIsLoading(true);
        setIsError(false);
        setHelperText(`File ${acceptedFileName}`);
        setLoadingProgress(0);

        const body = new FormData();
        body.append('file', acceptedFile, acceptedFileName);

        await axios
            .request({
                method: "post",
                url: `${FISHERCAT_API.url}/upload`,
                data: body,
                onUploadProgress: ({ loaded, total }) => {
                    setLoadingProgress(getProgress(loaded, total));
                }
            })
            .then(() => {
                setIsUploaded(true);
                setHelperText(`File ${acceptedFileName} Uploaded`);
            })
            .catch((error) => {
                logger(error);
                setIsError(true);
                setHelperText('File upload error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onDropRejected: FileUploadProps['onDropRejected'] = (fileRejections) => {
        setIsError(true);
        setHelperText(fileRejections[0].errors[0].message);
    };

    const onSubmit = () => {
        const fileName = file?.name;

        handleClick({
            nextPageId: cta?.value || '',
            id: cta?.id || '',
            ...fileName && { data: {
                input: fileName
            }}
        })
    };

    return (
        <>
            <FileUpload
                accept={{'file/*': []}}
                uploaded={isUploaded}
                loading={isLoading}
                loadingProgress={loadingProgress}
                error={isError}
                helperText={helperText}
                multiple={isMultyChoise}
                maxSize={5368709120}
                maxFiles={1}
                onDrop={onDrop}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            />
            {cta && (
                <Button
                    // disabled={!isUploaded}
                    onClick={onSubmit}
                >
                    {cta.title || 'Next'}
                </Button>
            )}
        </>
    );
});

export default FileUploadScreen;
