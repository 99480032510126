import { createContext, FC, useEffect, useMemo, useState } from "react";
import { useGetQueryParams } from "../../hooks";
import { WidgetConfig } from "../../services/s3Servise/types";
import { AppContextProps, AppContextProviderProps, ScreenConfig, DecodedEmbedObject } from "./types";

export const ApplicationContext = createContext<AppContextProps>({
    step: 0,
    widgetConfig: null,
    screenConfig: {},
    persistentMenu: {},
});

const AppContextProvider: FC<AppContextProviderProps> = ({children}) => {
    const { env, appId, preview, streamId, pypeId, backIfClose } = useGetQueryParams();
    const [step, setStep] = useState<number>(0);
    const [widgetConfig, setWidgetConfig] = useState<WidgetConfig | null>(null);
    const [persistentMenu, setPersistentMenu] = useState<DecodedEmbedObject | undefined>();
    const [screenConfig, setScreenConfig] = useState<ScreenConfig>({});
    const [error, setError] = useState<Error | null>(null);
    const setNextStep = () => setStep((step) => step + 1);

    useEffect(() => {
        if (error) {
            throw new Error(error.message, error);
        }
    }, [error]);

    const catchError = (error: Error) => {
        setError(error);
    };

    const setScreenConfigAndMenu = (config: ScreenConfig) => {
        const menu = config.embed?.find(({ type }) => type === 'persistent_menu');
        const embed = config.embed?.filter(({ type }) => type !== 'persistent_menu');
        const configWithoutMenu = {
            ...config,
            embed
        };

        setScreenConfig(configWithoutMenu);

        if (menu) {
            setPersistentMenu(menu);
        }
    };

    // Don't forget add dependencies to useMemo dep array
    const appValue: AppContextProps = useMemo(() => ({
        env,
        persistentMenu,
        widgetConfig,
        screenConfig,
        step,
        appId,
        pypeId,
        streamId,
        preview,
        backIfClose,
        setNextStep,
        setWidgetConfig,
        setScreenConfig: setScreenConfigAndMenu,
        catchError
    }), [step, screenConfig, widgetConfig, persistentMenu, appId, preview, backIfClose, env, streamId, pypeId]);

    return (
        <ApplicationContext.Provider value={appValue}>
            {children}
        </ApplicationContext.Provider>
    )
};

export default AppContextProvider;
