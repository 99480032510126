export enum StorageItems {
  userInfo = 'userInfo',
  idleTime = 'idleTime',
}

export interface GetItemFromStorage {
  <T>(key: string): T | null;
}

export interface SetItemToStorage {
  <T>(key: string, value: T): void;
}

export interface RemoveItemFromStorage {
  (key: string): void;
}

export interface StorageHelperInterface {
  get: GetItemFromStorage;
  set: SetItemToStorage;
  remove: RemoveItemFromStorage;
}
