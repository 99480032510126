import { FC, memo } from "react";
import { ImageButtons, QuickReplies } from "@pypestream/fishercat-ui";
import { useEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type ButtonsScreenProps = ScreenEmbedComponentsConfig;

export const ButtonsScreen: FC<ButtonsScreenProps> = memo(({ config }) => {
    const { active, title, titleId, handleClick } = useEmbedComponent(config);
    const { options } = config;
    const isImage = options?.some(({ image }) => image);

    return (
        <>
            {title}
            {isImage ? (
                <ImageButtons ariaLabelledby={titleId}>
                    {options?.map(({ value = '', image = '', alt_text: imgAlt = '', label = '' }) => (
                        <ImageButtons.Button
                            key={value}
                            imgAlt={imgAlt || label}
                            imgSrc={image}
                            onClick={() => handleClick(value)}
                            active={active.includes(value)}
                        />
                    ))}
                </ImageButtons>
            ) : (
            <QuickReplies ariaLabelledby={titleId}>
                {config.options?.map(({ value = '', label = ''  }) => (
                    <QuickReplies.Button
                        key={value}
                        onClick={() => handleClick(value)}
                        active={active.includes(value)}
                    >
                        {label}
                    </QuickReplies.Button>
                ))}
            </QuickReplies>
            )}
        </>
    );
});

export default ButtonsScreen;
