import { FC, memo } from "react";
import { Carousel } from "@pypestream/fishercat-ui";
import { useFishercatEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type CarouselScreenProps = ScreenEmbedComponentsConfig;

export const CarouselScreen: FC<CarouselScreenProps> = memo(({ config }) => {
    const { titleId, active, handleClick } = useFishercatEmbedComponent(config);

    return (
        <Carousel ariaLabelledby={titleId}>
            {config.options?.map(({ id, value = '', image, title, subtitle }) => (
                <Carousel.Card
                    key={id}
                    imgSrc={image}
                    title={title}
                    subtitle={subtitle}
                    onClick={() => handleClick({ nextPageId: value, id })}
                    active={active.includes(id)}
                />
            ))}
        </Carousel>
    );
});

export default CarouselScreen;
