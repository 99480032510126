import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import { useFishercatContext, useIsomorphicLayoutEffect } from '.';
import { LAUNCHDARKLY_ENV } from '../env';
import { scrollToTop } from '../helpers';

export const useInitializeFishercatApp = () => {
  const { appId, step } = useFishercatContext();
  const [isError, setIsError] = useState<boolean>(false);
  const ldClient = useLDClient();
  const [documentReady, setDocumentReady] = useState<boolean>(false);
  const flags = useFlags();
  const { pe21974 } = flags;

  useEffect(() => {
    const id = setInterval(() => {
      const isReady = document.readyState === 'complete';

      if (documentReady) {
        clearInterval(id);

        return;
      }

      setDocumentReady(isReady);
    }, 500);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (!documentReady || !pe21974) {
      return;
    }

    const id = setTimeout(() => {
      setIsError(!appId);
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  }, [documentReady, appId, pe21974]);

  useEffect(() => {
    if (!appId) {
      return;
    }

    ldClient?.identify({
      key: appId,
      custom: {
        environment: LAUNCHDARKLY_ENV,
      },
    });
  }, [appId]);

  useIsomorphicLayoutEffect(() => {
    scrollToTop();
  }, [step]);

  return isError;
};

export default useInitializeFishercatApp;
