import axios from 'axios';
import React, { FC, memo, useState } from 'react';
import { Button, FileUpload, FileUploadProps, FileWithPath } from '@pypestream/fishercat-ui';
import { getProgress, logger } from '../../../helpers';
import { useEmbedComponent } from '../../../hooks';
import { SendEmbedMessagePayload } from '../../../services/webSocketService/types';
import { ScreenEmbedComponentsConfig } from '../types';

type FileUploadScreenProps = ScreenEmbedComponentsConfig;

export const FileUploadScreen: FC<FileUploadScreenProps> = memo(({ config }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingProgress, setLoadingProgress] = useState<number>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>('');
    const [file, setFile] = useState<FileWithPath | null>(null);
    const { title, handleSendEmbed } = useEmbedComponent(config);
    const { multiple_choice: isMultyChoise, endpoint, max_size: maxSize, form_data: formData } = config;

    const onDrop: FileUploadProps['onDrop'] = (files) => setFile(files[0]);

    const onDropAccepted: FileUploadProps['onDropAccepted'] = async (files) => {
        const acceptedFile = files[0];
        const acceptedFileName = acceptedFile.name;

        setIsLoading(true);
        setIsError(false);
        setHelperText(`File ${acceptedFileName}`);
        setLoadingProgress(0);

        const body = new FormData();
        Object.entries(formData || {}).forEach((item) => body.append(item[0], item[1]));
        body.append('file', acceptedFile, acceptedFileName);

        await axios
            .request({
                method: "post",
                url: endpoint,
                data: body,
                onUploadProgress: ({ loaded, total }) => {
                    setLoadingProgress(getProgress(loaded, total));
                }
            })
            .then(() => {
                setIsUploaded(true);
                setHelperText(`File ${acceptedFileName} Uploaded`);
            })
            .catch((error) => {
                logger(error);
                setIsError(true);
                setHelperText('File upload error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onDropRejected: FileUploadProps['onDropRejected'] = (fileRejections) => {
        setIsError(true);
        setHelperText(fileRejections[0].errors[0].message);
    };

    const onSubmit = () => {
        const fileName = file?.name;

        const payload: Partial<SendEmbedMessagePayload> = {
            msg: fileName,
            file_status: 'ready',
            app_object: JSON.stringify({
                // TODO if bot_value field is equal '' - response without error
                bot_value: {
                    status: "success",
                    file_name: fileName
                },
                msg: fileName,
                type: 'file_upload'
            })
        };

        handleSendEmbed(payload);
    };

    return (
        <>
            {title}
            <FileUpload
                accept={{'image/*': ['.png', '.jpeg', '.jpg', '.gif']}}
                uploaded={isUploaded}
                loading={isLoading}
                loadingProgress={loadingProgress}
                error={isError}
                helperText={helperText}
                multiple={isMultyChoise}
                maxSize={maxSize}
                maxFiles={1}
                onDrop={onDrop}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            />
            <Button disabled={!isUploaded} onClick={onSubmit}>Next</Button>
        </>
    );
});

export default FileUploadScreen;
