import React from 'react';
import { DecodedEmbedObject } from '../../../../context/AppContextProvider/types';
import * as Screens from '../../../screens';

interface RenderEmbedComponents {
    (embed?: DecodedEmbedObject[]): (JSX.Element | null)[] | undefined;
}

export const renderEmbedComponents: RenderEmbedComponents = (embed) => {
    return embed?.map((config, index) => {
        const { type, id } = config;
        const key = `${id}${type}${index}`;
        const componentProps = { key, config };

        switch(type) {
            case 'quick_reply':
                return <Screens.QuickReplyScreen {...componentProps} />
            case 'button':
                return <Screens.ButtonsScreen {...componentProps} />
            case 'listpicker':
            case 'selection':
                return <Screens.ListPickerScreen {...componentProps} />
            case 'carousel':
                return <Screens.CarouselScreen {...componentProps} />
            case 'webview':
                return <Screens.WebViewScreen {...componentProps} />
            case 'secure_text':
                return <Screens.SecureTextScreen {...componentProps} />
            case 'file_upload':
                return <Screens.FileUploadScreen {...componentProps} />
            case 'datepicker':
                return <Screens.DatePickerScreen {...componentProps} />
            case 'form':
                return <Screens.FormScreen {...componentProps} />
            default:
                return null;
        }
    });
};

export default renderEmbedComponents;
