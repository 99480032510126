import React from 'react';
import { MutatedEmbedItem } from '../../../../services/fishecatApiService/types';
import * as Screens from '../../../fishercatScreens';

interface RenderEmbedComponents {
    (embeds?: MutatedEmbedItem[]): (JSX.Element | null)[] | undefined;
}

export const renderEmbedComponents: RenderEmbedComponents = (embeds) => {
    return embeds?.map((config, index) => {
        const { type, id } = config;
        const key = `${id}${type}${index}`;
        const componentProps = { key, config };

        switch(type) {
            // case 'secure_text':
            //     return <Screens.SecureTextScreen {...componentProps} />
            case 'datepicker':
                return <Screens.DatePickerScreen {...componentProps} />
            case 'video':
                return <Screens.VideoScreen {...componentProps} />
            case 'image':
            case 'gif':
                return <Screens.ImageScreen {...componentProps} />
            case 'download':
                return <Screens.FileDownloadScreen {...componentProps} />
            case 'upload':
                return <Screens.FileUploadScreen {...componentProps} />
            case 'carousel':
                return <Screens.CarouselScreen {...componentProps} />
            case 'button':
                return <Screens.QuickReplyScreen {...componentProps} />
            case 'image-button':
                return <Screens.ButtonsScreen {...componentProps} />
            case 'listpicker':
                return <Screens.ListPickerScreen {...componentProps} />
            case 'webview':
                return <Screens.WebViewScreen {...componentProps} />
            case 'text-entry':
                return <Screens.FormScreen {...componentProps} />
            default:
                return null;
        }
    });
};

export default renderEmbedComponents;
