/* eslint-disable react/no-danger */
import { FC, useEffect, useState } from 'react';
import Head from 'next/head';

export const PypestreamIntegration: FC = () => {
    const [wrapper, setWrapper] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const id = setInterval(() => {
            if (wrapper) {
                clearInterval(id);
                return;
            }

            setWrapper(window.document.getElementById('pypestream-wrapper'));
        }, 100);

        return () => clearInterval(id);
    }, [wrapper])

    const makeSupportVisible = () => {
        if (!wrapper) {
            return;
        }

        wrapper.style.visibility = 'visible';
        wrapper.style.zIndex = '100';
    }

    const supportButton = wrapper && (
        <button
            className='psfc-support-button'
            onClick={makeSupportVisible}
            style={{
                marginTop: 20,
                fontWeight: 700,
                fontSize: 14,
                border: '3px solid black',
                borderRadius: 10,
                padding: 5,
                position: 'fixed',
                textTransform: 'uppercase',
                bottom: 10,
                left: 10,
                zIndex: 1000
            }}
        >
            support
        </button>
    );
    return (
        <>
            {supportButton}
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                                window.pypestreamConfig = {
                                    APP_ID: '55291e70-7801-49da-a26b-fd57eeb2ff85',
                                    first_name: '', // TODO: Input user's first name
                                    last_name: 'Visitor', // TODO: Input user's last name
                                    phone: '', // TODO: Input user's phone number
                                    email: '', // TODO: Input user's email address
                                    screen_name: 'Screen 1', // TODO: Input user's landing page
                                    token: '', // TODO: Input user token
                                    passthrough: '', // TODO: Pass any additional data
                                    language: 'en',
                                    play_chime: false
                                };

                                function insertScript () {
                                    var script = document.createElement('script');
                                    script.type = 'text/javascript';
                                    script.src = 'https://web-sandbox.demo1.im.pype.tech/launcher.js?ts=' + Date.now();
                                    script.async = true;
                                    document.body.appendChild(script);
                                }

                                if (document.readyState === 'complete') {
                                    insertScript();
                                } else {
                                    window.addEventListener('load', insertScript);
                                }
                            `
                    }}
                />
            </Head>
      </>
    )
};

export default PypestreamIntegration;
