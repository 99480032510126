import { FC, memo } from 'react';
import { Button, Video } from '@pypestream/fishercat-ui';
import { ScreenEmbedComponentsConfig } from '../types';
import { useFishercatEmbedComponent } from '../../../hooks';

type VideoScreenProps = ScreenEmbedComponentsConfig;

export const VideoScreen: FC<VideoScreenProps> = memo(({ config }) => {
    const { handleClick } = useFishercatEmbedComponent(config);
    const { mediaSrc, cta } = config;

    return (
        <>
            <Video>
                <source src={`${mediaSrc || ''}#t=0.001`} type="video/mp4" />
            </Video>
            {cta && (
                <Button onClick={() => handleClick({ nextPageId: cta.value, id: cta.id })}>{cta.title || 'Next'}</Button>
            )}
        </>
    )
});

export default VideoScreen;
