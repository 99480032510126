import { FC, memo } from "react";
import { Carousel } from "@pypestream/fishercat-ui";
import { useEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type CarouselScreenProps = ScreenEmbedComponentsConfig;

export const CarouselScreen: FC<CarouselScreenProps> = memo(({ config }) => {
    const { active, title, titleId, handleClick } = useEmbedComponent(config);

    return (
        <>
            {title}
            <Carousel ariaLabelledby={titleId}>
                {config.options?.map(({ value = '', link, title, subtitle }) => (
                    <Carousel.Card
                        key={value}
                        imgSrc={link}
                        title={title}
                        subtitle={subtitle}
                        onClick={() => handleClick(value)}
                        active={active.includes(value)}
                    />
                ))}
            </Carousel>
        </>
    );
});

export default CarouselScreen;
