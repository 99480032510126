import { useState, useId, useCallback, useMemo } from "react";
import { MarkdownTitle as Title } from "../components/combined";
import { DecodedEmbedObject } from "../context/AppContextProvider/types";
import { SendEmbedMessagePayload } from "../services/webSocketService/types";
import { useMessagingContext } from "./useMessagingContext";

export const useEmbedComponent = (config: DecodedEmbedObject) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [active, setActive] = useState<string[]>([]);
    const { send, sendEmbed } = useMessagingContext();
    const id = useId();

    const {
      label,
      multiple_choice:
      isMultyChoise,
    } = config;

    const titleId = useMemo(() => label ? id : undefined, []);
    const title = useMemo(() => label ? <Title id={titleId}>{label}</Title> : null, []);

    const handleClick = useCallback((value: string) => {
        const isItemActive = active.includes(value);

        if (isItemActive) {
          setActive(active.filter((item) => item !== value));
        } else {
          setActive([...active, value]);
        }

        if (!isMultyChoise && !isClicked) {
            setIsClicked(true);
            send?.(value);
        }
    }, [active]);

    const handleSendEmbed = useCallback((data: Partial<SendEmbedMessagePayload>) => {
      if (!isClicked) {
        setIsClicked(true);
        sendEmbed?.(data);
      }
    }, []);

    return {
        title,
        titleId,
        active,
        handleClick,
        handleSendEmbed
    }
};

export default useEmbedComponent;
