import { FC, ChangeEvent, useState, KeyboardEvent, useCallback, useMemo, memo } from "react";
import { Icon, IconButton, TextInput } from "@pypestream/fishercat-ui";
import { useFishercatEmbedComponent } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type FormScreenProps = ScreenEmbedComponentsConfig;

export const FormScreen: FC<FormScreenProps> = memo(({ config }) => {
    const { titleId, handleClick } = useFishercatEmbedComponent(config);
    const [value, setValue] = useState<string>('');

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target?.value);
    }, []);

    const onButtonClick = useCallback(() => {
        handleClick?.({
            nextPageId: config.value || '',
            id: config.id,
            data: {
                input: value
            }
        });
    }, [value]);

    const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && value) {
            onButtonClick();
        }
    }, [value]);

    const suffix = useMemo(() => (
        <IconButton label="Continue" disabled={!value} onClick={onButtonClick}>
            <Icon name="arrow-left" />
        </IconButton>
    ), [value]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextInput
                label={config.label || "Start typing…"}
                aria-labelledby={titleId}
                suffix={suffix}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyDown}
            />
        </div>
    );
});

export default FormScreen;
