import { useFlags } from 'launchdarkly-react-client-sdk';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isEmpty } from '../helpers';
import { Env } from '../services/messagingService/types';

interface QueryParams {
  appId?: string;
  streamId?: string;
  pypeId?: string;
  preview?: string;
  backIfClose: boolean;
  env?: Env;
}

export const useGetQueryParams = () => {
  const [params, setParams] = useState<QueryParams>({
    appId: undefined,
    pypeId: undefined,
    streamId: undefined,
    env: undefined,
    preview: undefined,
    backIfClose: false,
  });

  const router: NextRouter = useRouter();
  const flags = useFlags();

  useEffect(() => {
    if (isEmpty(flags)) {
      return;
    }

    const { pe21583, pe21968 } = flags;

    setParams({
      appId: router?.query?.id as string | undefined,
      pypeId: router?.query?.pypeId as string | undefined,
      streamId: router?.query?.streamId as string | undefined,
      preview: router?.query?.preview as string | undefined,
      backIfClose: pe21583 && Boolean(router?.query?.backHere as string | undefined),
      env: pe21968
        ? (router?.query?.env as Env | undefined) || 'live'
        : (router?.query?.env as Env | undefined) || 'sandbox',
    });
  }, [router?.query, flags]);

  return params;
};

export default useGetQueryParams;
