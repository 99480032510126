import { FC, memo } from "react";
import { PersistentMenu, PersistentMenuProps } from "@pypestream/fishercat-ui";
import { useEmbedComponent } from "../../../hooks";
import { SendEmbedMessagePayload } from "../../../services/webSocketService/types";
import { ScreenEmbedComponentsConfig } from "../types";

type MenuProps = ScreenEmbedComponentsConfig;

export const Menu: FC<MenuProps> = memo(({ config }) => {
    const options = config.options?.map(({ label, value }) => ({
        label: label || '',
        value: value || ''
    })) || [];

    const { handleSendEmbed } = useEmbedComponent(config);

    const onChange: PersistentMenuProps['onChange'] = ({ value }) => {
        const val = `${value}`;
        const msg = options.find(({ value }) => value === val)?.label || '';

        const payload: Partial<SendEmbedMessagePayload> = {
            msg,
            app_object: JSON.stringify({
                type:'text',
                msg,
                bot_value: val,
                embed_type: 'persistent_menu',
                free_text: false,
            })
        };

        handleSendEmbed(payload);
    };

    return (
        <PersistentMenu
            icon='👋'
            label={config.label || 'Need help with something else?'}
            mobileLabel="Need help?"
            defaultValue={options[0]}
            options={options}
            onChange={onChange}
        />
    );
});

export default Menu;
