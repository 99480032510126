import { FC, memo, useCallback } from "react";
import { Button, ListPicker } from "@pypestream/fishercat-ui";
import { ScreenEmbedComponentsConfig } from "../types";
import { useEmbedComponent, useMessagingContext } from "../../../hooks";

type ListPickerScreenProps = ScreenEmbedComponentsConfig;

export const ListPickerScreen: FC<ListPickerScreenProps> = memo(({ config }) => {
    const { send } = useMessagingContext();
    const { active, title, titleId, handleClick } = useEmbedComponent(config);
    const { multiple_choice: isMultyChoise, options } = config;
    const onSubmit = useCallback(() => send?.(active.toString()), [active]);

    return (
        <>
            {title}
            <ListPicker ariaLabelledby={titleId} isMulti={isMultyChoise}>
                {options?.map(({ image, value = '', label = '', alt_text: imgAlt, description }) => (
                    <ListPicker.Item
                        key={value}
                        title={label}
                        imgAlt={imgAlt || label}
                        imgSrc={image}
                        subtitle={description}
                        onClick={() => handleClick(value)}
                        active={active.includes(value)}
                    />
                ))}
            </ListPicker>
            {isMultyChoise && (
                <>
                    <div style={{ marginBottom: 60 }} />
                    <Button
                        disabled={!active.length}
                        onClick={onSubmit}
                    >
                        Next
                    </Button>
                </>
            )}
        </>
    );
});

export default ListPickerScreen;
