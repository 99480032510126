import { FC, memo } from 'react';
import { Layout, Stepper, Header, NotificationContainer } from '@pypestream/fishercat-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MarkdownTitle as Title } from "../MarkdownTitle";
import { EndButton } from '..';
import { DivElement } from '../../../types/globals';
import { renderEmbedComponents } from './helpers';
import { MutatedPage } from '../../../services/fishecatApiService/types';

interface FishercatScreenLayoutProps extends DivElement {
    config: MutatedPage | null;
    loading?: boolean;
    step: string;
    logo?: string;
    error: boolean;
    // menu?: DecodedEmbedObject;
}

export const FishercatScreenLayout: FC<FishercatScreenLayoutProps> = memo(({
    logo,
    config,
    loading,
    step,
    error
}) => {
    const { pe22016 } = useFlags();
    const titleText = error ? 'App id doesn\'t exist' : config?.title;

    return (
        <>
            <Header>
                {logo && (
                    <Header.Logo src={logo} alt="logo" />
                )}
                {/* {menu && (
                    <Menu config={menu} />
                )} */}
                <EndButton />
            </Header>
            <NotificationContainer />
            <main>
                <Stepper activeId={step} showLabel={pe22016}>
                    <Layout
                        // coverSrc={background?.src}
                        // coverType={background?.type}
                        // coverAlt={background?.alt}
                        loading={loading}
                    >
                        {titleText && (
                            <Title id={config?.id}>
                                {titleText}
                            </Title>
                        )}
                        {renderEmbedComponents(config?.embeds)}
                    </Layout>
                </Stepper>
            </main>
        </>
    );
});

export default FishercatScreenLayout;
