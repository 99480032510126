import { useFlags } from "launchdarkly-react-client-sdk";
import { FC } from "react";
import AppContextProvider from "../../../context/AppContextProvider/AppContextProvider";
import { FishercatContextProvider } from "../../../context/FishercatContextProvider";
import MessagingContextProvider from "../../../context/MessagingContextProvider/MessagingContext";
import { FishercatHomePage } from "./FishercatHomePage";
import { HomePage as OldHomepage } from "./HomePage";

export const HomePage: FC = () => {
    const { pe22066 } = useFlags();

    return pe22066 ? (
        <FishercatContextProvider>
            <FishercatHomePage />
        </FishercatContextProvider>
    ) : (
        <AppContextProvider>
            <MessagingContextProvider>
                <OldHomepage />
            </MessagingContextProvider>
        </AppContextProvider>
    );
};

export default HomePage;
