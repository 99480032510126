import { useState, useEffect } from 'react';

export const useBrowserActiveTab = () => {
  const [isActiveTab, setIsActiveTab] = useState<boolean>(true);

  useEffect(() => {
    const events = [
      {
        handler: () => setIsActiveTab(true),
        event: 'focus',
      },
      {
        handler: () => setIsActiveTab(false),
        event: 'blur',
      },
    ];

    events.forEach(({ event, handler }) => {
      window.addEventListener(event, handler);
    });

    return () => {
      events.forEach(({ event, handler }) => {
        window.removeEventListener(event, handler);
      });
    };
  }, []);

  return isActiveTab;
};
