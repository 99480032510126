import { createContext, FC, useMemo } from "react";
import { useEngagement } from "../../hooks";
import { MessagingStateProps, MessagingContextProviderProps } from "./types";

export const MessagingContext = createContext<MessagingStateProps>({});

const MessagingContextProvider: FC<MessagingContextProviderProps> = ({ children }) => {
  const { create, send, sendEmbed, end } = useEngagement();

  const messagingContextValue = useMemo(() => ({
    start: create,
    send,
    sendEmbed,
    end
  }), [create, send, sendEmbed, end]);

  return (
    <MessagingContext.Provider value={messagingContextValue}>
      {children}
    </MessagingContext.Provider>
  );
};

export default MessagingContextProvider;
