import axios, { AxiosInstance, AxiosResponse } from 'axios';
import logger from '../../helpers/logger';
import { GetWidgetConfig, S3ServiceInterface, WidgetConfig } from './types';

export class S3Service implements S3ServiceInterface {
  private contentType = 'application/json';

  private api: AxiosInstance | null = null;

  constructor(private readonly apiUrl: string) {
    this.apiUrl = apiUrl;

    this.api = this.createAxiosInstance(this.apiUrl);
  }

  private createAxiosInstance = (baseURL: string) => {
    return axios.create({
      baseURL,
      headers: {
        'Content-Type': this.contentType,
        Accept: this.contentType,
      },
    });
  };

  public getWidgetConfig: GetWidgetConfig = async (appId) => {
    if (!this.api) {
      throw new Error("S3Api API doesn't exist");
    }

    const { data } = await this.api.get<any, AxiosResponse<WidgetConfig | null>>(`/pd${appId}`);

    logger(data);

    return data;
  };
}

export default S3Service;
