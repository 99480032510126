import { FC, ChangeEvent, useState, KeyboardEvent, useCallback, memo } from "react";
import { PasswordInput } from "@pypestream/fishercat-ui";
import { useEmbedComponent, useMessagingContext } from "../../../hooks";
import { ScreenEmbedComponentsConfig } from "../types";

type SecureTextScreenProps = ScreenEmbedComponentsConfig

export const SecureTextScreen: FC<SecureTextScreenProps> = memo(({ config }) => {
    const { send } = useMessagingContext();
    const [value, setValue] = useState<string>('');
    const { title } = useEmbedComponent(config);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target?.value);
    }, []);

    const onButtonClick = useCallback(() => {
        send?.(value);
    }, [value]);

    const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && value) {
            onButtonClick();
        }
    }, [value]);

    return (
        <>
            {title}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PasswordInput value={value} onChange={handleChange} onKeyDown={onKeyDown} />
            </div>
        </>
    );
});

export default SecureTextScreen;
