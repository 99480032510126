import { useFlags } from 'launchdarkly-react-client-sdk';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useAppContext, useInitializeApp } from '../../../hooks';
import { PypestreamIntegration, ScreenLayout, UserTimeout } from '..';

export const HomePage: NextPage = () => {
  const { pe21053, pe21735V1 } = useFlags();
  const { appId, step, widgetConfig, screenConfig, persistentMenu } = useAppContext();
  const isStartError = useInitializeApp();
  const isLoading = !Object.keys(screenConfig).length && !isStartError;
  const logo = widgetConfig?.pype_logo;
  const pageTitle = widgetConfig?.widget_name;

  return (
    <>
      <Head>
        <meta name="description" content="Fishercat App" />
        <link rel="shortcut icon" href={logo} />
      </Head>
      <ScreenLayout
        config={screenConfig}
        loading={isLoading}
        logo={logo}
        menu={persistentMenu}
        step={`${step}`}
        error={isStartError}
      />
      {pe21735V1 && (
        <PypestreamIntegration />
      )}
      {pe21053 && appId && (
        <UserTimeout appId={appId} title={pageTitle} />
      )}
    </>
  );
};

export default HomePage;
