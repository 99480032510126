import { FC, memo } from 'react';

interface HiddenTimerProps {
    time: number;
}

export const HiddenTimer: FC<HiddenTimerProps> = memo(({ time }) => {
    return (
        <span data-timer={`${time} sec`} style={{ position: 'fixed', zIndex: -9999 }} />
    )
});

export default HiddenTimer;
