import { FC, memo } from "react";
import { QuickReplies } from "@pypestream/fishercat-ui";
import { ScreenEmbedComponentsConfig } from "../types";
import { useEmbedComponent } from "../../../hooks";

type QuickReplyScreenProps = ScreenEmbedComponentsConfig;

export const QuickReplyScreen: FC<QuickReplyScreenProps> = memo(({ config }) => {
    const { active, title, titleId, handleClick } = useEmbedComponent(config);

    return (
        <>
            {title}
            <QuickReplies ariaLabelledby={titleId}>
                {config.options?.map(({ value = '', label = ''  }) => (
                    <QuickReplies.Button
                        key={value}
                        onClick={() => handleClick(value)}
                        active={active.includes(value)}
                    >
                        {label}
                    </QuickReplies.Button>
                ))}
            </QuickReplies>
        </>
    );
});

export default QuickReplyScreen;
